<template>
    <div id="Orders" class="m-0 p-0 row">

        <div class="col-12 d-flex flex-wrap p-0 m-0 justify-content-start justify-content-xl-end align-items-end py-2 py-xl-4">

            <div class="col-4 col-xxl-3 position-relative">
                <input @input="filterElements"
                    class="custom_input opensans-bold text-dark pl-3 w-100"
                    type="text"
                    v-model="search_query"
                    placeholder="Buscar"
                    />
                <img class="position-absolute"
                    style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                    :src="lupa_icon_b" />
            </div>
            <div class="col-4 col-xxl-3">
                <ns-select @change="filterElements"
                    theme="light"
                    class="opensans-bold text-dark"
                    label="Filtrar por:"
                    variant="white"
                    :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                    v-model="filter"
                    full
                    rounded>
                </ns-select>
            </div>
            <div class="col-4 col-xxl-3 px-2 px-xl-5">
                <button @click.stop="program_modal = true;"
                    class="shadow_btn py-2 mr-3 w-100"
                    type="button">
                    Agregar Paso
                </button>
            </div>
        </div>

        <div class="col-12" v-if="loading">
            <ns-spinner size="sm" variant="success"/>
        </div>

        <div class="col-12" v-if="empty_search && !search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con el filtro seleccionado.
            </p>
        </div>

        <div class="col-12" v-if="empty_search && search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con esos parámetros de búsqueda.
            </p>
        </div>

        <div class="col-12" v-if="!loading && !fetch_error && !empty_search && programs_data.length > 0">
            <ns-table :data="programs_data" 
                :allow_headers="programs_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">

                <template v-slot:image="data">
                    <div class="flex-center">
                        <div style="max-width: 60px;">
                            <img :src="data.value" 
                                class="img-fluid">
                        </div>
                    </div>
                </template>

                <template v-slot:active="data">
                    {{ data.item.active ? 'Activo' : 'Inactivo'}}
                </template>

                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 position-relative">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                                class="position-absolute d-flex flex-column bg-white custom_position" 
                                style="width: 200px; height: 150px; z-index: 15; top: -5vh; right: 0vw;" v-if="data.item.open">

                                <button @click.stop="selectTableItem('detail', data.item.id)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Detalles
                                </button>

                                <button @click.stop="selectTableItem('edit', data.item.id, data.item)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id)" v-if="data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>

                                <button @click.stop="selectTableItem('activate', data.item.id)" v-if="!data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Activar
                                </button>
                            </div>

                        </div>

                    </div>
                </template>
            </ns-table>
        </div>

        <!---- Paginator ----->

        <div class="col-12 py-3" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-evenly align-items-end">

                <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <span class="mr-3 opensans-bold text-dark">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 50px;"
                        v-model="rows"
                        class="custom_input text-center"
                        :disabled="page > 1"
                        />
                    
                    <ns-select @change="filterElements"
                        theme="light"
                        class="opensans-bold text-dark ml-2"
                        style="width: 180px;"
                        variant="white"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc"
                        full>
                    </ns-select>
                </div>



                <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-end pr-0 pr-xl-4 py-3 py-lg-0">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="custom_input text-center mx-4"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!----------- Modales  ------------->
        <ns-modal :show_modal="program_modal" bg_color="white" :max_width="680"
            overlay_close @onClose="program_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Paso" 
                        theme="light"
                        v-model="step_name"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.step_name">
                        {{ errors.step_name  }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Número de paso" 
                        theme="light"
                        v-model="step_number"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.step_number">
                        {{ errors.step_number  }}
                    </span>
                </div>

                <div class="col-12 pr-4 pl-4 pt-3">
                   <label class="text-dark">
                        Descripción:
                    </label>
                    <textarea class="form-control bg-light  w-100"
                        rows="10"
                        style="border: none; border-radius: 15px;"
                        v-model="body">
                    </textarea>
                    <span class="text-danger" v-if="errors.body">
                        {{ errors.body }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Imagen Destacada
                    </label>
                    <ns-dropfile 
                        _id="image_1"
                        @charged="checkFile"
                        style="height: 255px;"
                        variant="success">
                    </ns-dropfile>
                    <span class="text-danger" v-if="errors.image">
                        {{ errors.image }}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Preview
                    </label>
                    <div style="max-width:300px;">
                        <img class="img-fluid"
                        :src="preview_image" />
                    </div>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Video:" 
                        theme="light"
                        v-model="video"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.video">
                        {{ errors.video  }}
                    </span>
                </div>

                <div class="col-12 col-md-6 mt-4 montserrat-bold">
                    <div class="row p-0 justify-content-end align-items-center h-100">
                        <div class="col-12  d-flex justify-content-between p-0 px-3">
                            <button @click.stop="createEditElement"
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ns-modal>

        <ns-modal :show_modal="delete_modal" bg_color="white" max_width="680"
            overlay_close @onClose="delete_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de eliminar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="inactivateElement"
                        class="btn btn-danger btn-pill"
                        type="button">
                        Eliminar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="active_modal" bg_color="white" max_width="680"
            overlay_close @onClose="active_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de activar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="activateElement"
                        class="btn btn-success btn-pill"
                        type="button">
                        Activar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="detail_modal" bg_color="white" max_width="680"
            overlay_close @onClose="detail_modal = false">
            <div class="row w-100 justify-content-between montserrat-regular text-dark">

                <div class="col-12" style="font-size: 22px;">
                    <p class="text-black montserrat-semibold">
                        Detalles
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Paso:</strong>  
                       <span class="d-block">
                            {{ step.step_name}}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Paso número:</strong>  
                       <span class="d-block">
                            {{ step.step_number }}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                        <strong>Descripción:</strong>  
                        <span class="d-block" v-html="step.body">
                        </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Imagen:</strong>  
                       <span class="d-block">
                            {{ step.image }}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>video:</strong>  
                       <span class="d-block">
                            {{ step.video }}
                       </span>
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-end">
                    <button @click.stop="detail_modal = false"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cerrar
                    </button>
                </div>
                
            </div>
        </ns-modal>
    </div>
</template>

<script>
    import lupa_icon_b from 'ASSETS/icons/lupa_icon_b.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import star from 'ASSETS/icons/star.png'
    import star_fill from 'ASSETS/icons/star_fill.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon_b,
                arrow_left,
                star,
                star_fill,
                program_modal: false,
                delete_modal: false,
                active_modal: false,
                detail_modal: false,
                search_query: null,
                loading: true,
                fetch_error: false,
                empty_search: false,
                programs_headers: [
                    {key: 'step_name', label: 'Paso'},
                    {key: 'step_number', label: 'Número de paso'},
                    {key: 'image', label: 'Imagen'},
                    {key: 'video', label: 'video'},
                    {key: 'active', label: 'Estatus'},
                    {key: 'actions', label: 'Acciones'}
                ],
                programs_data: [],
                step_name: null,
                step_number: null,
                body: null,
                image: null,
                preview_image: null,
                video: null,
                questionary_id: null,
                errors: {
                    step_name: null,
                    step_number: null,
                    body: null,
                    image: null,
                    video: null,
                    questionary_id: null
                },
                filter: 'all',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        methods: {
            //helpers.
            clearFields() {
             
                this.step_name = null
                this.step_number = null
                this.body = null
                this.image = null
                this.preview_image = null
                this.video = null
                this.questionary_id = null

                this.program_modal = false
                this.delete_modal = false
                this.active_modal = false
                this.detail_modal = false

                for(let error in this.errors) {
                    this.errors[error] = null
                }
            },
            async selectTableItem(type, id, data = null){
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'detail':
                        await this.$store.dispatch('programs/view', { id_step: id})
                        this.step.body = this.step.body.replaceAll('\n', '<br>')
                        this.detail_modal = true
                        break;
                    case 'edit':
                        this.selected_id = id
                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }
                        await this.$store.dispatch('programs/view', { id_step: this.selected_id})
                        this.step_name = this.step.step_name
                        this.step_number = this.step.step_number.toString()
                        this.body = this.step.body
                        this.preview_image = this.step.image
                        this.questionary_id = this.step.questionary_id
                        this.video = this.step.video
                        this.program_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                    case 'activate':
                        this.selected_id = id
                        this.active_modal = true
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            },
            async filterElements(){
                let search = ''
                this.loading = true
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('programs/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('programs/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('programs/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.steps === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                    this.loading = false
                }
                
               this.programs_data = this.steps.map( step => {
                    return {
                        ...step,
                        actions: '-',
                        open: false
                    }
                })

                
            },
            checkFile(file){
                this.preview_image = file.url;
                this.image = file.url;  
            },
            // posts CRUD
            async createEditElement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.step_name) || _.isEmpty(this.step_name)) {
                    this.errors.step_name = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.step_number) || _.isEmpty(this.step_number)) {
                    this.errors.step_number = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.body) || _.isEmpty(this.body)) {
                    this.errors.description = '*Campo requerido'
                    complete = false
                }


                if(complete) {

                    if(this.selected_id) {
                        let payload = {
                            body: {
                                step_name: this.step_name,
                                step_number: this.step_number,
                                body: this.body,
                                image: this.image,
                                video: this.video,
                                questionary_id: this.questionary_id
                            },
                            query: {
                                id_step: this.selected_id
                            }
                        }

                        await this.$store.dispatch('programs/update', payload)
                        this.clearFields()
                        this.filterElements()
                    }

                    else {
                        let payload = {
                            step_name: this.step_name,
                            step_number: this.step_number,
                            body: this.body,
                            image: this.image,
                            video: this.video,
                            questionary_id: this.questionary_id
                        }

                        
                        await this.$store.dispatch('programs/add', payload)
                        this.clearFields()
                        this.filterElements()
                    }
                }
            },
            async inactivateElement() {
                await this.$store.dispatch('programs/delete', { id_step: this.selected_id, active: false})
                this.clearFields()
                this.filterElements()
            },
            async activateElement() {
                await this.$store.dispatch('programs/delete', { id_step: this.selected_id, active: true})
                this.clearFields()
                this.filterElements()
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        computed: {
            ...mapGetters({
                step: 'programs/getProgram',
                steps: 'programs/getPrograms',
                last_page: 'programs/getLastPage',
                total_pages: 'programs/getTotalPages'
            })
        },
        async created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'PROGRAMA')
                this.$store.commit('setIndex', 7)

                //list of recipes
                await this.$store.dispatch('programs/list', {datatable: true, page: this.page, rows: this.rows, order_asc: false, active: true})

                if(this.steps) {
                    this.programs_data = this.steps.map( program => {
                        return {
                            ...program,
                            actions: '-',
                            open: false
                        }
                    })
                }
                

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.error(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style scoped>
    .custom_position {
        right: 10;
        
    }

    .custom_input {
        background-color: #e9ecef;
        border: none;
        height: 40px;
        border-radius: 10px;
    }

    @media screen and (max-width: 928px){
            .custom_position {
                right: 0;
            }
        }
</style>